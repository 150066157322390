import axios, { AxiosRequestConfig } from 'axios';
const uuid = require('uuid/v4');
// const auth = new AuthForgeRock();

// import AuthForgeRock from './actions/auth/auth';

// export const requireBearerToken = () => {
//     axios.interceptors.request.use((config:AxiosRequestConfig) => {               
//         config.headers.common.Authorization = `Bearer ${auth.getAccessToken()}`;
//         config.headers.common["x-id-token"] = `${auth.getIdToken()}`;
//         return config;
//     });
// }


export const requireCorrelationId = () => {
    axios.interceptors.request.use((config:AxiosRequestConfig) => {  
        if (config.headers && config.headers.common) config.headers.common["x-correlation-id"] = uuid();       
        return config;
    });
}