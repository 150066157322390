import * as React from "react";
import { Router } from "react-router-dom";
import { Header } from 'ukas.uux.react-components';
import { Footer } from "./common/footer";
import config from "../app-config";
require("@webcomponents/webcomponentsjs/webcomponents-bundle");
require("@webcomponents/webcomponentsjs/custom-elements-es5-adapter");
require("@pearson-ux/footer");
import Nav from "./common/nav";
import Routes, { breadcrumb as Breadcrumb } from "./routes";
import { AuthProfile } from "models/auth";
import {
  User,
  UserLearningProvider
} from "../models/user-details-forgerock-request";
import AccessDeniedModal from "../features/sample-media-request/shared/access-denied-modal";
import AccountLockedModal from "./account-locked-modal";
import MediaUpload from "./media-upload/media-upload.container";
import GlobalAlertList from "../features/global-alert-list/global-alert-list.container.component";
import { MediaRequest } from "../models/media-request";
import moment from "moment";
import Auth from '../actions/auth/auth';
import { detect } from 'detect-browser'
const auth = new Auth();
import InformationModalGeneric from '../features/sample-media-request/shared/information-modal-generic';
import { browserVersions } from '../features/sample-media-request/shared/constants';
const PearsonElementsSdk = require('@pearson-components/modal');
import { history } from './router';
import UserDataProvider  from "../shared/context-api/provider";

export interface DispatchProps {
  signin?: () => void;
  signout?: () => void;
  userDetailsRequests?: (forgeRockId: string) => Promise<void>;
  loadSampleMediaRequests?: (learningProviderId: string) => Promise<void>;
  getSampleMediaRequestStarted?: (status: boolean)  => void;
  getSampleMediaRquestsSuccessAction?: (status: any)  => void;
  getSampleMediaRequestCompleted?: (status: any)  => void;
  setSelectedUserId?: (userId: string) => void;
  loadSampleMediaRequestsByLearningProviderCode?: (
    learningProviderCode: string
  ) => Promise<void>;
  validateUserPermissions?: (
    family_name: string,
    loggedInUserPermissionIds: number[]
  ) => Promise<void>;
  getEDetails?: () => Promise<void>;
  setSessionAlive?: () => void;
  checkSessionAliveFailureAction?: () => void;
}

export interface AppProps {
  loading?: boolean;
  isAuthenticated?: boolean;
  profile?: AuthProfile;
  learningProviderId?: string;
  mediaRequest: MediaRequest[];
  proxyName: string;
  proxyId: string;
  userDetailsByForgerock: User;
  userPermissionValidated: boolean;
  showCreateMenu: boolean;
  selectedUserId: string
  isSessionAlive: boolean;
}

interface State {
  showText: string;
  selectedLearner: { item: string; value: string };
  showAccessDeniedModal: boolean;
  selectedUserType: string;
  appendedUserDetails: UserLearningProvider[];
  isAccountLocked: boolean;
  showBrowserCompatibileModal: boolean;
  isSessionAvailable: boolean;
}

type Props = DispatchProps & AppProps ;

export class App extends  React.Component<Props, State> {
  timer: NodeJS.Timeout;
  checkSessionTime: NodeJS.Timeout;
  checkBrowserValidTime: NodeJS.Timeout;
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedLearner: { item: "Select a Learning Provider", value: "0" },
      showText: "",
      isAccountLocked: false,
      showAccessDeniedModal: false,
      selectedUserType: "Select a Learning Provider",
      appendedUserDetails: [],
      isSessionAvailable: true,
      showBrowserCompatibileModal: false
    };
    
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.helpIconRedirct = this.helpIconRedirct.bind(this);
    this.onUSerDetailsDropdownChange = this.onUSerDetailsDropdownChange.bind(
      this
    );
    this.onCloseAccessDeniedModal = this.onCloseAccessDeniedModal.bind(this);
    this.onCloseBrowserCompatibleModal = this.onCloseBrowserCompatibleModal.bind(this);
    this.onCloseSessionExpiredModal = this.onCloseSessionExpiredModal.bind(this);
  }


  render() {
    const isAuthenticated = this.props.isAuthenticated;
    let signedInUser = "";
    if (this.props.userDetailsByForgerock && this.props.userDetailsByForgerock.firstName) 
    {
       signedInUser = `${this.props.userDetailsByForgerock.firstName} ${this.props.userDetailsByForgerock.lastName}`; 
    }
    const text = {
      headerTitle: "Access Denied",
      bodyText: "",
      closeButtonSRText: "Close",
      modalSaveButtonText: "Ok",
      modalCancelButtonText: "Close"
    };
    const sessionText = {
      headerTitle: "Session Expiry",
      bodyText: "",
      closeButtonSRText: "Close",
      modalSaveButtonText: "Ok",
      modalCancelButtonText: "Close"
    }
    const browserCompatibleText = {
      headerTitle: "You may need to upgrade your browser",
      bodyText: "",
      closeButtonSRText: "Close",
      modalSaveButtonText: "Ok",
      modalCancelButtonText: "Ok"
    };
    let proxyName = "";
    if (this.props.mediaRequest.length > 0) {
      proxyName = this.props.mediaRequest[0].proxyName
        ? this.props.mediaRequest[0].proxyName
        : "";
    }
    const userDetails = this.props.userDetailsByForgerock.associatedLearningProviders
    if(userDetails && userDetails.length > 1){
      localStorage.setItem("proxyLpId", "0");
    }

    return (
      <div>
    <UserDataProvider>
        <Router history={history}>
   
          <div>
       
            <Header
              signedIn={isAuthenticated} 
              signedInUser={signedInUser} 
              onSignIn={this.login} 
              onSignOut={this.logout}
              onShowHelp={this.helpIconRedirct}
              showHelp
            />
            <Nav
              loading={this.props.loading == null ? false : this.props.loading}
              isAuthenticated={isAuthenticated!}
              userType={
                this.props.profile ? this.props.profile.family_name : ""
              }
              proxyName={proxyName}
              learningProviderFilterLabel={this.state.selectedLearner.item}
              onUSerDetailsDropdownChange={this.onUSerDetailsDropdownChange}
              userPermissionValidated={this.props.userPermissionValidated}
              userDetails={
                this.props.userDetailsByForgerock.associatedLearningProviders
              }
              // appendedUserDetails = {appendedUserDetails}
              selectedUserType={this.state.selectedUserType}
              showCreateMenu={this.props.showCreateMenu}
              proxyLPName = {this.getProxyLPName()}
            />

            <div className="app">
              <Breadcrumb />
              <Routes />
              <div className="pe-strategy__centered--small-gap">&nbsp;</div>
              <div>
                {this.props.isAuthenticated === true && (
                  <MediaUpload {...(this.props as any)} />
                )}
              </div>
              <div>
                {this.props.isAuthenticated === true && (
                  <GlobalAlertList {...(this.props as any)} />
                )}
              </div>
            </div>
          </div>
        </Router>
        <Footer />
        </UserDataProvider>
         <PearsonElementsSdk.Modal
          isShown={!this.state.isSessionAvailable}
          text={sessionText}
          footerVisible={false}
          cancelBtnHandler={this.onCloseSessionExpiredModal}           
        >
          <InformationModalGeneric
            closeBtnHandler={this.onCloseSessionExpiredModal}
            infoMsgRender={this.sessionExpirynMsgRender}
            closeBtnText={"Ok"}
          />
        </PearsonElementsSdk.Modal>
        <PearsonElementsSdk.Modal
          isShown={
            this.state.showBrowserCompatibileModal
          }
          text={browserCompatibleText}
          footerVisible={false}
          cancelBtnHandler={this.onCloseBrowserCompatibleModal}
        >
          <InformationModalGeneric 
            closeBtnHandler={this.onCloseBrowserCompatibleModal}
            infoMsgRender = {this.getBrowserCompatibleMsg}
          />
        </PearsonElementsSdk.Modal>

        <PearsonElementsSdk.Modal
          isShown={this.state.isAccountLocked}
          text={text}
          footerVisible={false}
          cancelBtnHandler={this.onCloseAccessDeniedModal}
        >
          <AccountLockedModal
            isAccountLocked={this.state.isAccountLocked}
            cancelBtnHandler={this.onCloseAccessDeniedModal}
            userType={
              this.props.profile && this.props.profile.family_name
                ? this.props.profile.family_name
                : ""
            }
          />
        </PearsonElementsSdk.Modal>
        <PearsonElementsSdk.Modal
          isShown={
            this.state.showAccessDeniedModal && !this.state.isAccountLocked
          }
          text={text}
          footerVisible={false}
          cancelBtnHandler={this.onCloseAccessDeniedModal}
        >
          <AccessDeniedModal
            cancelBtnHandler={this.onCloseAccessDeniedModal}
            userType={
              this.props.profile && this.props.profile.family_name
                ? this.props.profile.family_name
                : ""
            }
          />
        </PearsonElementsSdk.Modal>
      </div>
    );
  }

  onCloseSessionExpiredModal = () => {
    this.logout();
  }

  sessionExpirynMsgRender(){
    return (<div>
      <p>
        Your browser session is going to expire in next 2 minutes. To continue using the application, you are required to login again.
        <br /><br />Please click <strong>Ok</strong> to go to the login page.
      </p>
    </div>);
  }
  checkBrowserCompatibility = () => {
    const browserDetect = detect();
    const browserName = browserDetect ? browserDetect.name : "";
    const browserVersion = browserDetect ? browserDetect.version : "";
    let iscompatible = false;
     
    if (browserName && browserName.toLowerCase().indexOf("msie") !== -1 || browserName && browserName.toLowerCase() === "edge") {
      iscompatible = false;
    } else if (browserName.toLowerCase().indexOf("chrome") !== -1 && 
    browserVersion && parseInt(browserVersion, 10) >= browserVersions.CHROME) {
      iscompatible = true;
    } else if (browserName && browserName.toLowerCase().indexOf("firefox") !== -1 &&
    browserVersion && parseInt(browserVersion, 10) >= browserVersions.FIREFOX) {
      iscompatible = true;
    } else if (browserName && browserName.toLowerCase().indexOf("safari") !== -1 &&
    browserVersion && parseInt(browserVersion, 10) >= browserVersions.SAFARI) {
      iscompatible = true;
    } else if (browserName && browserName.toLowerCase().indexOf("edge-chromium") !== -1 &&
    browserVersion && parseInt(browserVersion, 10) >= browserVersions.EDGECHROMIUM) {
      iscompatible = true;
    }
    return this.setState({showBrowserCompatibileModal : !iscompatible})
  }

  getBrowserCompatibleMsg(){
    return (<div>
      <p>
      This application is best viewed through Safari 12, Chrome 76, Firefox 69, Edge-Chromium 80 or any higher version of these browsers. 
        <br />You may have difficulty using some of the features. For a better experience, please install any of the recommended browser versions.
      </p>
    </div>);
  }

  onCloseBrowserCompatibleModal(){
    this.setState({showBrowserCompatibileModal:false})
  }
  onCloseAccessDeniedModal = () => this.logout();
  checkSession = () => {
      try{
        if(!auth.isAuthenticated()) {
          clearInterval(this.checkSessionTime);
          this.setState({isSessionAvailable : false, showBrowserCompatibileModal :false})
          setTimeout(()=>{auth.signout()}, 110000);
        } 
      } catch(e){
        console.log(e);
      }

  }

  componentWillUnmount(){
    localStorage.setItem("selectedUserId", "0");
  }

  componentDidMount() {
    // getHistory().push('/Maintenance')
    this.checkBrowserValidTime =setTimeout(()=>{this.checkBrowserCompatibility()},2000);
    this.checkSessionTime = setInterval(()=>{this.checkSession()},60000);
    const isAuthenticated = this.props.isAuthenticated!;
    setTimeout(() => this.props.userDetailsRequests!, 500);
    let username = "";
    if (this.props.profile) {
      username = this.props.profile.name;
    }

    this.setHeader(isAuthenticated, username);

    document.addEventListener("oAppHeader.login", e => {
      this.login();
    });

    document.addEventListener("oAppHeader.logout", e => {
      this.logout();
    });
    document.addEventListener("oAppHeader.help.toggle", e => {
      this.helpIconRedirct();
    });

    this.timer = setTimeout(() => {
      if (
        this.props.profile &&
        this.props.profile.family_name &&
        this.props.profile.profile
      ) {
        localStorage.setItem("userType", this.props.profile.family_name);

        this.props.userDetailsRequests!(this.props.profile.profile)
          .then(() => {
            if (this.props.getEDetails) {
              this.props.getEDetails();
            }
            this.setState({
              showAccessDeniedModal: !this.props.userPermissionValidated
            });
            const currentDate = new Date().toLocaleDateString();
            const lockedUntil = moment(
              this.props.userDetailsByForgerock.lockedUntil
            ).format("MM/DD/YYYY");
            if (moment(lockedUntil).isAfter(currentDate)) {
              this.setState({ isAccountLocked: true });
            }

            //  this.isUserPermissionsvalid();
            if (
              this.props.userDetailsByForgerock.associatedLearningProviders && this.props.userDetailsByForgerock.associatedLearningProviders
                .length === 1
            ) {
              this.props.loadSampleMediaRequests!(
                this.props.userDetailsByForgerock.associatedLearningProviders[0]
                  .learningProvider.learningProviderId + ""
              )
                .then()
                .catch(err => {
                  if (err && err.message) {
                    console.log(err.message);
                  }
                });
              localStorage.setItem(
                "proxyLpId",
                this.props.userDetailsByForgerock.associatedLearningProviders[0]
                  .learningProvider.learningProviderId + ""
              );
            }
          })
          .catch(userDetailsRequestsErr => {
            if (userDetailsRequestsErr && userDetailsRequestsErr.message) {
              console.log(userDetailsRequestsErr.message);
            }
          });
      }
    }, 1000);




    if (
      this.props.learningProviderId !== undefined &&
      this.props.proxyName === "" && this.props.learningProviderId.length <= 5
    ) {
      if (isAuthenticated) {
        // load the default data here
        this.props.loadSampleMediaRequests!(this.props.learningProviderId)
          .then()
          .catch(loadSampleMediaRequestsErr => {
            if (loadSampleMediaRequestsErr && loadSampleMediaRequestsErr.message) {
              console.log(loadSampleMediaRequestsErr.message);
            }
          });
      }
    }

    if (
      localStorage.getItem("selectedUserType") !== null &&
      localStorage.getItem("selectedUserId") !== null
    ) {
      setTimeout(() => {
        if (
          localStorage.getItem("selectedUserType") !==
          "Select a Learning Provider"
        ) {
          this.props.loadSampleMediaRequests!(
            localStorage.getItem("selectedUserId")!
          )
            .then()
            .catch(loadSampleMediaRequestsError => {
              if (loadSampleMediaRequestsError && loadSampleMediaRequestsError.message) {
                console.log(loadSampleMediaRequestsError.message);
              }
            });
        }
      }, 1000);
    }

    if (this.props.proxyName !== "") {
      this.props.loadSampleMediaRequestsByLearningProviderCode!(
        this.props.proxyId
      )
        .then()
        .catch(loadSampleMediaRequestsByLearningProviderCodeErr => {
          if (loadSampleMediaRequestsByLearningProviderCodeErr && loadSampleMediaRequestsByLearningProviderCodeErr.message) {
            console.log(loadSampleMediaRequestsByLearningProviderCodeErr.message);
          }
        });
    }


  }

  componentWillReceiveProps(nextProps: any){
    if (
      (this.props.profile &&
      this.props.profile.family_name &&
      this.props.profile.profile) 
      && (this.props.selectedUserId !== nextProps.selectedUserId)
    ) {
      this.props.userDetailsRequests!(this.props.profile.profile)
    }
  }
  
  componentDidUpdate(prevProps: Props) {
    if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
      if (
        this.props.learningProviderId !== undefined &&
        this.props.proxyName === "" && this.props.learningProviderId.length <= 5
      ) {
        this.props.loadSampleMediaRequests!(this.props.learningProviderId)
          .then()
          .catch(loadSampleMediaRequestserr => {
            if (loadSampleMediaRequestserr && loadSampleMediaRequestserr.message) {
              console.log(loadSampleMediaRequestserr.message);
            }
          });
      }

      if (this.props.proxyName !== "") {
        this.props.loadSampleMediaRequestsByLearningProviderCode!(
          this.props.proxyId
        )
          .then()
          .catch(loadSampleMediaRequestsByLearningProviderCodeerr => {
            if (loadSampleMediaRequestsByLearningProviderCodeerr && loadSampleMediaRequestsByLearningProviderCodeerr.message) {
              console.log(loadSampleMediaRequestsByLearningProviderCodeerr.message);
            }
          });
      }

      if (this.props.profile !== prevProps.profile) {
        let username = "";
        if (this.props.profile) {
          username = `${this.props.profile.name}`;
        }
        this.setHeader(this.props.isAuthenticated, username);
      }
    }

    if (
      this.props.learningProviderId &&
      (this.props.learningProviderId !== prevProps.learningProviderId) &&
      this.props.userDetailsByForgerock.associatedLearningProviders && this.props.userDetailsByForgerock.associatedLearningProviders
        .length === 1
    ) {
      this.props.loadSampleMediaRequests!(
        this.props.learningProviderId
      )
    }

  }


  setHeader = (isAuthenticated: boolean, username: string) => {
   const header =true;
  
   return header;
  };

  login() {
    this.props.signin!();
  }

  logout() {
  auth.signout();
  }
  helpIconRedirct() {
    const redirectLink = this.props.profile!.family_name === "Internal" ? config.HELP_LINKS.HELP_INTERNAL : config.HELP_LINKS.HELP;
     window.open(redirectLink);
  }

  getProxyLPName = () =>{
    let lpName:any = 'Select a Learning Provider';
    if(localStorage.getItem('selectedUserType') != null){
      lpName = localStorage.getItem('selectedUserType')
    }
    return lpName;
  }

  onUSerDetailsDropdownChange(selectedUserType: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    // const selectedUserType = (event.target as HTMLInputElement).value;
    localStorage.setItem("selectedUserType", selectedUserType);
    if (
      selectedUserType === "Select a Learning Provider"
    ) {
      this.props.setSelectedUserId!("0");
      localStorage.setItem("selectedUserId", "0");
      localStorage.setItem("proxyLpId", "0");
      history.push('/sample-requests');
      this.props.getSampleMediaRquestsSuccessAction!([])
      this.props.getSampleMediaRequestStarted!(false);
      this.props.getSampleMediaRequestCompleted!(false);
      return; 
    }
    this.props.userDetailsByForgerock.associatedLearningProviders.forEach(
      userDetail => {
        const name = userDetail.learningProvider.vqLearningProviderCode
          ? userDetail.learningProvider.gqLearningProviderCode
            ? userDetail.learningProvider.vqLearningProviderCode +
              "/" +
              userDetail.learningProvider.gqLearningProviderCode +
              " " +
              userDetail.learningProvider.learningProviderName
            : userDetail.learningProvider.vqLearningProviderCode +
              " " +
              userDetail.learningProvider.learningProviderName
          : userDetail.learningProvider.gqLearningProviderCode +
            " " +
            userDetail.learningProvider.learningProviderName;
        
        const userName = name.replace("/null", "");
        const modifiedSelectedUserType = selectedUserType.replace(" ", "");
        const modifiedUser = userName.replace(" ", "");
        const VQOnlyUserName = modifiedUser.replace("/"+userDetail.learningProvider.gqLearningProviderCode,"");
        
        if (VQOnlyUserName.trim() === modifiedSelectedUserType.trim()) {
          localStorage.setItem(
            "selectedUserId",
            userDetail.learningProvider.learningProviderId.toString()
          );
          localStorage.setItem(
            "proxyLpId",
            userDetail.learningProvider.learningProviderId.toString()
          );

          this.props.setSelectedUserId!(userDetail.learningProvider.learningProviderId.toString());
        }
      }
    );
    if (
      this.props.learningProviderId !== undefined ||
      this.props.learningProviderId !== null
    ) {
      history.push('/sample-requests');
      this.props.loadSampleMediaRequests!(
        localStorage.getItem("selectedUserId")!
      )
        .then()
        .catch(loadSampleMediaRequestserror => {
          if (loadSampleMediaRequestserror && loadSampleMediaRequestserror.message) {
            console.log(loadSampleMediaRequestserror.message);
          }
        });
    }

    return this.setState({ selectedUserType });
  }
}

export default App;
