import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import loading from '../callback/loading.svg';


import { MediaRequest } from "models/media-request";
import { LearningProvider } from "models/learning-provider";

import CreateMediaSetForm from "./create-media-set-form";

export interface StateProps {
  learningProvider: LearningProvider
  mediaSet: MediaRequest | null;
  userPermissionValidated: boolean;
  showCreateMenu: boolean;
}

export interface DispatchProps {
  onSave: (mediaSet: MediaRequest) => Promise<void>;
  getEDetails?: () => Promise<void>;
}

export type OwnProps = RouteComponentProps<{}>;

type Props = OwnProps & StateProps & DispatchProps;

export class LearnerUnitInput {
  learnerId: string = "";
  learnerCode:string = "";
  firstName: string = "";
  surname: string = "";
  dateOfBirth: Date = new Date();
  unitId: string = "";
  unitName: string = "";
  gender: string;
  genderId: number;
  learningProviderCode: string = "";
  learningProviderName: string = "";
}

interface LocalState {
  isBusy: boolean;
  mediaRequest: MediaRequest;
  qualificationId: string;
  qualificationName: string;
  learnerUnit: LearnerUnitInput;
  validations: Validations,
  mediaSampleTypes :string[],
  selectedMediaSampleType : string,
  selectedGender:string,
  selectedGenderId: number,
  loading: boolean,
}

export class Validations {
  errors:any = {};
  validationFlags: ValidationFlags = new ValidationFlags;
}

class ValidationFlags {  
  deadlineDateTime:boolean = true;
  learnerId:boolean = false;
  firstName:boolean = false;
  surname:boolean = false;
  unitId:boolean = false;
  unitName:boolean = false;
  learningProviderId:boolean = true;
  learningProviderName:boolean = true;
  standardsVerifierId:boolean = true;
  standardsVerifierName:boolean = false;
  dateOfBirth:boolean = true;
  purpose:boolean = true;
  qualificationId:boolean = false;
  qualificationName:boolean = false;
}

export class CreateMediaSet extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    document.title="Create Media Set";
    this.onFieldChange = this.onFieldChange.bind(this);
    this.saveNewMediaSet = this.saveNewMediaSet.bind(this);
    this.render = this.render.bind(this);
    this.onDeadlineChanged = this.onDeadlineChanged.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onLearnerDateOfBirthChanged = this.onLearnerDateOfBirthChanged.bind(this);
    this.onLearnerUnitInputChange = this.onLearnerUnitInputChange.bind(this);
    this.onLearnerUnitAdd = this.onLearnerUnitAdd.bind(this);
    this.redirectAfterSave = this.redirectAfterSave.bind(this);
    this.validateInputElement = this.validateInputElement.bind(this);
    this.isAddLearnerEnabled = this.isAddLearnerEnabled.bind(this);
    this.isSaveButtonEnabled = this.isSaveButtonEnabled.bind(this);
    this.onMediaSampleTypeChanged = this.onMediaSampleTypeChanged.bind(this);
    this.onGenderChanged = this.onGenderChanged.bind(this);

    const mediaSampleTypes:string[] = ['Sample per Learner', 'Sample per Cohort'];
    this.state = {
      isBusy: false,
      mediaRequest: Object.assign({}, this.props.mediaSet, {purpose:'1st Sample'}),
      qualificationId: '',
      qualificationName: '',
      learnerUnit: {} as LearnerUnitInput,      
      validations: new Validations(),
      mediaSampleTypes,
      selectedMediaSampleType: mediaSampleTypes[0],
      selectedGender:'Not Known',
      selectedGenderId: 3,
      loading: true
    };
  }
  componentWillMount(){
    if(this.props.userPermissionValidated === false) {
      this.props.history.push('/home')
    }    
    if(this.props.getEDetails && this.props.showCreateMenu === false){
      this.props.getEDetails().then(this.showNotFoundPage)
    } else {
      this.setState({loading:false})
    }
  }

  componentWillReceiveProps(){
    if(this.props.userPermissionValidated === false) {
      this.props.history.push('/home')
    } 
  }

  render() {
    const style = {
      backgroundColor: 'white',
      bottom: 0,
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'absolute' as 'absolute',
      right: 0,
      top: 0,
      width: '100vw'
    };
    return (
      <div>
        { this.state.loading &&
        <div style={style}>
          <img src={loading} alt="loading" />
        </div>}
        <CreateMediaSetForm
          onFieldChange  = {this.onFieldChange}
          onSave  = {this.saveNewMediaSet}
          onDeadlineChanged  = {this.onDeadlineChanged}        
          onClear  = {this.onClear}        
          mediaSet  = {this.state.mediaRequest}
          learnerUnit  = {this.state.learnerUnit}
          onLearnerDateOfBirthChange  = {this.onLearnerDateOfBirthChanged}
          onLearnerUnitInputChange  = {this.onLearnerUnitInputChange}
          onLearnerAdd  = {this.onLearnerUnitAdd}
          learningProvider  = {this.props.learningProvider}
          errors  = {this.state.validations.errors}
          addLearnerEnabled = {this.isAddLearnerEnabled()}
          saveButtonEnabled = {this.isSaveButtonEnabled()}
          mediaSampleTypes = {this.state.mediaSampleTypes}
          onMediaSampleTypeChange = {this.onMediaSampleTypeChanged}
          selectedMediaSampleType = {this.state.selectedMediaSampleType}
          onGenderChange = {this.onGenderChanged}        
          genderTypes = {['Not Known', 'Man', 'Woman', 'Other'] }
        />
      </div>
    );
  }

  onFieldChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();

    const element = event.target as HTMLInputElement;    
    const mediaRequest = Object.assign({}, this.state.mediaRequest);
    mediaRequest[element.id] = element.value;
    
    const validations = Object.assign({}, this.state.validations, this.validateInputElement(element.id, element.value));

    return this.setState({ mediaRequest, validations });
  }

  showNotFoundPage = () =>{
      if(this.props.showCreateMenu === false) {
        this.setState({loading:false})
        this.props.history.push('/not-found');
      }else {
        this.setState({loading:false})
      }
  }

  onLearnerUnitInputChange(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();

    const element = event.target as HTMLInputElement;
    const field = element.id;
    const learnerUnit = Object.assign({}, this.state.learnerUnit);
    learnerUnit[field] = element.value;

    const validations = Object.assign({}, this.state.validations, 
      this.validateInputElement(element.id, element.value));

    return this.setState({ learnerUnit, validations });
  }

  saveNewMediaSet(event: Event) {
    event.preventDefault();

    const selectedMediaSampleType = this.state
      .selectedMediaSampleType.toUpperCase().replace(/ /g, '_');

    const mediaSet = Object.assign({}, this.state.mediaRequest);
    mediaSet.learningProviderId = this.props.learningProvider.id;
    mediaSet.learningProviderName = this.props.learningProvider.name;
    mediaSet.mediaSampleType = selectedMediaSampleType;

    this.props.onSave(mediaSet).then(x => {         
        this.redirectAfterSave();
    });
  }

  redirectAfterSave() {
    this.props.history.push("/sample-requests");
  }

  onDeadlineChanged(newDeadline: string) {
    const mediaRequest = Object.assign({}, this.state.mediaRequest);
    mediaRequest.deadlineDateTime = moment(newDeadline, "DD/MM/YYYY").toDate();
    return this.setState({ mediaRequest });
  }

  onLearnerDateOfBirthChanged(newDateOfBirth: string) {
    const learnerUnit = Object.assign({}, this.state.learnerUnit);
    learnerUnit.dateOfBirth = moment(newDateOfBirth, "DD/MM/YYYY").toDate();
    return this.setState({ learnerUnit });
  }

  onLearnerUnitAdd(event: React.FormEvent<HTMLSelectElement>) {
    event.preventDefault();

    const mediaRequest = Object.assign({}, this.state.mediaRequest);
    const learnerUnit = Object.assign({}, new LearnerUnitInput, this.state.learnerUnit);

    if (learnerUnit.gender === undefined) {
      learnerUnit.gender = "Not Known";
      learnerUnit.genderId = 3;
    }

    mediaRequest.learnerUnits.push({ 
      learnerId: learnerUnit.learnerId,
      learnerCode:learnerUnit.learnerCode,
      firstName: learnerUnit.firstName,
      surname: learnerUnit.surname,
      dateOfBirth: learnerUnit.dateOfBirth,
      unitId: learnerUnit.unitId,
      unitName: learnerUnit.unitName,
      gender: learnerUnit.gender,
      genderId: learnerUnit.genderId,
      fileIds: [],
      learningProviderCode  : learnerUnit.learningProviderCode,
      learningProviderName  : learnerUnit.learningProviderName
    });

    return this.setState({ mediaRequest, learnerUnit: new LearnerUnitInput() });
  }

  onClear() {
    this.setState({      
      mediaRequest: Object.assign({}, this.props.mediaSet),
      learnerUnit: {} as LearnerUnitInput,      
      validations: new Validations()      
    });
  }

  validateInputElement(prop:string, value:any):Validations {       
    const result:Validations = Object.assign({}, this.state.validations);

    switch(prop) {
      case 'learnerId' :
      case 'firstName' :
      case 'surname' :
      case 'unitId' :
      case 'unitName' :
      case 'learningProviderId' :
      case 'learningProviderName' :
      case 'standardsVerifierId' :
      case 'standardsVerifierName' :
      case 'purpose' :
      case 'qualificationId':
      case 'qualificationName':
      {
        if (value === '') {
          result.errors[prop] = 'required';
          result.validationFlags[prop]= false;
        } else {
          result.errors[prop] = undefined;
          result.validationFlags[prop] = true;
        }
      }
    }

    return result;
  }

  isAddLearnerEnabled():boolean {
    const flags = this.state.validations.validationFlags;

    return flags.learnerId && flags.firstName && flags.surname
      && flags.unitId && flags.unitName;      
  }

  isSaveButtonEnabled():boolean {
    const flags = this.state.validations.validationFlags;
    return flags.qualificationId 
      && flags.qualificationName 
      && flags.deadlineDateTime 
      && flags.learningProviderId 
      && flags.learningProviderName
      && flags.standardsVerifierId 
      && flags.standardsVerifierName
      && flags.purpose;
  }

  onMediaSampleTypeChanged(event: React.FormEvent<HTMLSelectElement>) {
    event.preventDefault();

    const selectedMediaSampleType = (event.target as HTMLInputElement).value;

    return this.setState({ selectedMediaSampleType });
  }

  onGenderChanged(event: React.FormEvent<HTMLSelectElement>) {
    event.preventDefault();

    let selectedGender:string = (event.target as HTMLInputElement).value;
    let selectedGenderId:number = 0;
    const learnerUnit = Object.assign({}, this.state.learnerUnit);

    switch(selectedGender) {
      case "Man":
        selectedGenderId = 1;
        break;
      case "Woman":
        selectedGenderId = 2;
        break;
      case "Not Known":
        selectedGenderId= 3;
        break;
      case "Other":
        selectedGenderId = 4;
        break;
    }

    // default to not known
    if (selectedGenderId === 0) {
      selectedGenderId = 3;
      selectedGender = 'Not Known';      
    }

    learnerUnit.gender = selectedGender;
    learnerUnit.genderId = selectedGenderId;

    return this.setState({ selectedGender, selectedGenderId, learnerUnit })

  };
}

export default CreateMediaSet;
