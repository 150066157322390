import  React , { StrictMode } from 'react';
import  ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import App from './components/app.container';
import './index.css';
import AuthForgeRock from './actions/auth/auth';
const auth = new AuthForgeRock();
import * as registerServiceWorker from './registerServiceWorker';
import configureStore from './store/configureStore';
import { InitialState } from './reducers/initialState';
import { requireCorrelationId } from './axios-interceptors';
import { ThemeProvider } from 'styled-components';
import { theme } from 'ukas.uux.react-components';


const store = configureStore(InitialState);

// requireBearerToken();
requireCorrelationId();
function startApp() {
  ReactDOM.render(
    <StrictMode>
    <Provider store={store}>
      <IntlProvider locale={'en-GB'}>
      <ThemeProvider theme={theme}>
        <App />
        </ThemeProvider>
      </IntlProvider>
    </Provider>
    </StrictMode>,
    document.getElementById('root') as HTMLElement
  );
}

const idTokenDecrypted = localStorage.getItem("payload");
if(window.location.pathname === "/logout"){
  auth.signout();
}

if(window.location.pathname === "/callback" && 
  window.location.search.indexOf("code") > -1) 
  {
    startApp();
} else {
  if(!idTokenDecrypted) {
    auth.signin();
  } else {
    startApp();
  }
}
registerServiceWorker.unregister();