export class User {
  firstName: string | null;
  lastName: string | null;
  associatedLearningProviders: UserLearningProvider[];
  permissionGroups : PermissionGroups[]
  lockedUntil?: Date = new Date();
  internalUser?: boolean;
  isUserPermissionValid: boolean;
  userPermissions : userRoleAccess;
  constructor() {
      this.associatedLearningProviders = [];
      this.permissionGroups= [];
    }
}

export class userRoleAccess {
  lwtReadAccess: boolean;
  lwtWriteAccess: boolean;
}

export class UserLearningProvider {
  learningProvider: LearningProvider;
  learningProviderId: number;
 
}

export class LearningProvider{
  learningProviderId : number=0;
  gqLearningProviderCode: string='';
  vqLearningProviderCode: string='';
  learningProviderName: string='';

}

export class PermissionGroups {
  permissionGroupId: number;
  permissionGroupName: string;
}

